<template>
  <div id="mine">
    <div class="header-box">
      <div class="user-info">
        <img class="user-avatar" v-lazy="userInfo['market_logo']" alt/>
        <div class="user-basic-info">
          <span class="user-name">{{userInfo['market_name']}}</span>
          <span class="business-hours">营业时间：{{userInfo['start_time']}} - {{userInfo['end_time']}}</span>
        </div>
      </div>
      <div class="orders-nav-bar">
        <div class="nav-item" @click="$router.push({path: '/serviceList'})">
          <img class="nav-icon" src="../assets/images/refund.png" alt/>
          <span class="nav-name">退款/售后</span>
          <!--  退款售后未处理订单数量，大于0时显示，大于99时显示'···'-->
          <span class="badge" v-if="userInfo['OrdersAfterSalesCount'] > 0">{{userInfo['OrdersAfterSalesCount'] > 99 ? userInfo['OrdersAfterSalesCount'] : '···'}}</span>
        </div>
        <div class="nav-item" @click="$router.push({path: '/myWallet'})">
          <img class="nav-icon" src="../assets/images/wallet.png" alt/>
          <span class="nav-name">钱包</span>
        </div>
        <div class="nav-item" @click="onClose(userInfo['business_status'])">
          <img class="nav-icon"
               :src="userInfo['business_status']==='1'?require('../assets/images/rest.png'):require('../assets/images/work.png')"
               alt/>
          <span class="nav-name">{{userInfo['business_status']==='1'?'歇业休息':'重新开业'}}</span>
        </div>
      </div>
    </div>
    <!--  菜单  -->
    <div class="menu-box">
      <div class="menu-item" @click="$router.push({path: '/shopSet'})">
        <div class="label">
          <img src="../assets/images/shop-set.png" alt/>
          <p>店铺设置</p>
        </div>
        <img class="arrow" src="../assets/images/arrow-nav.png" alt/>
      </div>
      <div class="menu-item" @click="$router.push({path: '/management'})">
        <div class="label">
          <img src="../assets/images/sell-icon.png" alt/>
          <p>在售商品</p>
        </div>
        <img class="arrow" src="../assets/images/arrow-nav.png" alt/>
      </div>
      <div class="menu-item" @click="$router.push({path: '/handleMenu'})">
        <div class="label">
          <img src="../assets/images/goods.png" alt/>
          <p>商品管理</p>
        </div>
        <img class="arrow" src="../assets/images/arrow-nav.png" alt/>
      </div>
      <div class="menu-item" @click="$router.push({path: '/problem'})">
        <div class="label">
          <img src="../assets/images/problem.png" alt/>
          <p>问题反馈</p>
        </div>
        <img class="arrow" src="../assets/images/arrow-nav.png" alt/>
      </div>
      <div class="menu-item" @click="$router.push({path: '/shareShop'})">
        <div class="label">
          <img src="../assets/images/share.png" alt/>
          <p>分享店铺</p>
        </div>
        <img class="arrow" src="../assets/images/arrow-nav.png" alt/>
      </div>
      <div class="menu-item" @click="check" v-if="false">
        <div class="label">
          <img src="../assets/images/login-out.png" alt/>
          <p>清点库存</p>
        </div>
        <img class="arrow" src="../assets/images/arrow-nav.png" alt/>
      </div>
      <div class="menu-item" @click="logout">
        <div class="label">
          <img src="../assets/images/login-out.png" alt/>
          <p>退出登录</p>
        </div>
        <img class="arrow" src="../assets/images/arrow-nav.png" alt/>
      </div>
    </div>
  </div>
</template>

<script>
  import {shopRest, userInfo} from "../api/requist";
  import {Toast} from "vant";
  import wxShare from "../common/js/wxShare";

  export default {
    name: "mine",
    data() {
      return {
        userInfo: {}
      }
    },
    mounted() {
      this.initUserInfo();
    },
    methods: {
      // 盘点库存
      check: function () {
        wxShare.onScan();
      },
      // 退出登录
      logout: function () {
        localStorage.clear();
        localStorage.removeItem("merch_id");
        sessionStorage.removeItem("firstPage");
        sessionStorage.removeItem("reload");
        this.$router.replace({path: "/authorize"});
      },
      // 打烊，歇业休息
      onClose: function (status) {
        if (status === '1') {
          // 营业中 -> 打烊
          this.$router.push({path: "/tackRest"});
        } else {
          // 打烊 -> 营业中
          let formData = new FormData();
          formData.append("merch_id", localStorage.getItem("merch_id"));
          formData.append("business_status", '1');
          shopRest(formData).then((res) => {
            if (res.code === 200) {
              Toast("开业成功");
              this.userInfo.business_status = "1";
            }
          });
        }
      },
      // 初始化用户信息
      initUserInfo: function () {
        let formData = new FormData();
        formData.append("merch_id", localStorage.getItem("merch_id"));
        userInfo(formData).then((res) => {
          if (res.code === 200) {
            console.log(res);
            this.userInfo = res.data;
          }
        });
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../common/css/theme.scss";

  #mine {
    background: #F5F7FA;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    .header-box {
      background-image: url(../assets/images/bg-mine-head.png);
      background-position: left top;
      background-repeat: no-repeat;
      background-size: 100%;
      background-clip: content-box;

      .user-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 16px 0 16px;

        .user-avatar {
          height: 55px;
          width: 55px;
          border: 2px solid #FFFFFF;
          border-radius: 50%;
        }

        .user-basic-info {
          margin-left: 8px;
          color: white;
          display: flex;
          flex-direction: column;

          .user-name {
            font-size: 16px;
            font-weight: bold;
          }

          .business-hours {
            margin-top: 4px;
            font-size: 14px;
          }
        }
      }

      .orders-nav-bar {
        margin: 14px 16px 0 16px;
        padding: 12px 0;
        border-radius: 4px;
        background: white;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .nav-item {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;

          .nav-icon {
            width: 36px;
            height: 36px
          }

          .nav-name {
            margin-top: 6px;
            font-size: 12px;
            color: #303133;
          }

          .badge {
            height: 17px;
            width: 17px;
            color: white;
            position: absolute;
            right: 0;
            top: 0;
            background: #FF4848;
            border-radius: 50%;
            border: 1px solid white;
            font-size: 12px;
            text-align: center;
            line-height: 17px;
          }
        }
      }
    }

    .menu-box {
      margin: 8px 16px;
      background: white;
      border-radius: 4px;
      padding: 12px;

      .menu-item {
        padding: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .label {
          height: 28px;
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            width: 28px;
            height: 28px;
          }

          p {
            margin-left: 8px;
            font-size: 14px;
            color: #303133;
          }
        }

        .arrow {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
</style>
